import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames"
import SEO from "../components/Layout/SEO"
import Hero from "../components/Layout/Hero"
import Container from "../components/Layout/Container"
import MediaBox from "../components/Elements/MediaBox"
import Button from "../components/Elements/Button"

import clinics from "../../static/images/icons/icons__analytics--colored.png"
import employeeBenefits from "../../static/images/icons/icons__vaccination--colored.png"
import patientSupport from "../../static/images/icons/icons__pharmacy--colored.png"

import styles from "../../src/components/StaticPages/utils/staticPages.module.scss"

export const query = graphql`
  query {
    corporate: file(
      relativePath: { eq: "corporate/corporate__brain--teal-long.png" }
    ) {
      childImageSharp {
        fluid(
          duotone: { highlight: "#ffffff", shadow: "#0d6d6e" }
          toFormat: PNG
          quality: 90
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const CorporatePage = ({ data }) => {
  return (
    <>
      <SEO title="Corporate" />

      <Hero
        background={{
          image: data.corporate.childImageSharp.fluid.src,
          position: "center",
        }}
        color="warning"
        className={classNames("py-3", styles["careers"])}
        size="small"
      >
        <Container isCentered desktop={10} fullhd={8} className="mt-2 mb-2">
          <div className={styles["banner__body"]}>
            <h1>Delivering healthcare everywhere</h1>
            <p className="mt-2 subtitle is-size-7-tablet is-size-7-mobile">
              MedGrocer's technology platforms optimize "medicine-as-a-service"
              for patients and companies. Its ePharmacy, corporate health
              services, and patient programs empower customers to get their
              medicines, vaccines, and medical services conveniently,
              cost-effectively, and intelligently.
            </p>
            <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
              Beyond just delivering medicines, we deliver healthcare solutions:
              from analytics, to customized programs, to end-to-end fulfillment.
            </p>
            <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
              In partnership with the country's top employers, health insurers,
              and multinational drug companies, we redefine the medicine
              experience for thousands of patients everyday.
            </p>
            <a
              href="mailto:solutions@medgrocer.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color="primary" className={styles["banner__button"]}>
                Contact Us
              </Button>
            </a>
          </div>
        </Container>
      </Hero>

      <Hero>
        <Container
          isCentered
          desktop={10}
          fullhd={10}
          className="mt-5-desktop mt-5-fullhd"
        >
          <div className="content has-text-centered pb-2">
            <h2>What we can do</h2>
          </div>
          <div className="columns is-centered content has-text-centered">
            <MediaBox
              title="ePharmacy"
              image={{ src: patientSupport, alt: "Patient Support Programs" }}
            >
              <ul>
                <li>Online Ordering</li>
                <li>Delivery</li>
                <li>Patient Support</li>
              </ul>
            </MediaBox>

            <MediaBox
              title="Corporate Healthcare"
              image={{ src: employeeBenefits, alt: "Corporate Healthcare" }}
            >
              <ul>
                <li>Medicine Benefits</li>
                <li>Testing and Vaccines</li>
                <li>Administration</li>
              </ul>
            </MediaBox>

            <MediaBox
              title="Company Clinics"
              image={{ src: clinics, alt: "Medicines" }}
            >
              <ul>
                <li>Clinic Management</li>
                <li>Medicines and Supplies</li>
                <li>Mental Health EAP (Employee Assistance Program)</li>
              </ul>
            </MediaBox>
          </div>
        </Container>
      </Hero>
    </>
  )
}

export default CorporatePage
